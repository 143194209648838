import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import Translate from '../Translate/Translate';
import LocalizedLink from '../LocalizedLink';
import useStores from '../../hooks/useStores';
import SuccessImage from '../../images/confirm.svg';
import FailImage from '../../images/fail.svg';
import { purchase } from '../../services/Pixel';
import './styles/PaymentReturn.scss';
import { gql, useMutation } from '@apollo/client';
import TagManager from '@sooro-io/react-gtm-module';

const EMPTY_CART = gql`
  mutation EmptyCart($cartToken: String!) {
    emptyCart(cartToken: $cartToken)
  }
`;

const PaymentReturn = () => {
  const { status } = useParams<{ status: string }>();
  const { search } = useLocation();
  const { cart, authentication } = useStores();
  const queryParams = new URLSearchParams(search);
  const currentOrder = queryParams.get('order');
  const [emptyCart] = useMutation(EMPTY_CART);

  const cartInitialized = cart.initialized;
  const authInitialized = authentication.initialized;
  const isLoggedIn = authentication.loggedIn;

  const handleEmptyCart = async () => {
    try {
      await emptyCart({
        variables: {
          cartToken: cart.token,
        },
      });
      cart.load();
    } catch (error) {
      console.error('Error emptying cart', error);
    }
  };

  useEffect(() => {
    if (status === 'success' && cartInitialized && authInitialized) {
      try {
        if (currentOrder) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'purchase',
              ecommerce: {
                currency: 'EUR',
                value: cart.totalSum,
                transaction_id: currentOrder,
                items: cart.variations.map((variation) => {
                  const product = cart.getProduct(variation.product_id);

                  const finalPrice = product.finalPrice(true);
                  const defaultPrice = product.defaultPrice;
                  const discountAmount = defaultPrice - finalPrice;

                  return {
                    item_id: variation.product_id,
                    item_variant: variation.value,
                    quantity: variation.quantity,
                    item_name: product.brandTitle + ' ' + product.title,
                    item_brand: product.brandTitle,
                    price: defaultPrice,
                    discount: Number(discountAmount.toFixed(2)),
                  };
                }),
              },
            },
          });
        }

        purchase(cart, isLoggedIn);
      } catch (e) {
        // Analytics errors can be ignored
      }
      if (status === 'success') {
        handleEmptyCart();
      }
    }
  }, [status, authInitialized, cartInitialized, isLoggedIn, currentOrder]);

  let content;
  if (status === 'success') {
    content = (
      <div className="section">
        <div className="section__box">
          <div className="section__title">
            <p>
              <Translate component="payment" keyword="success-message" />
            </p>
            <span>
              <Translate component="payment" keyword="success-info" />
            </span>
          </div>
          <div className="section__successImage">
            <img src={SuccessImage} alt="" />
          </div>
          <div className="section__buttons">
            <LocalizedLink to="/" className="section__button">
              <Translate component="payment" keyword="back-home" />
            </LocalizedLink>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="section">
        <div className="section__box">
          <div className="section__title">
            <p>
              <Translate component="payment" keyword="fail" />
            </p>
          </div>
          <div className="section__successImage">
            <img src={FailImage} alt="" />
          </div>
          <div className="section__buttons">
            <LocalizedLink to="/" className="section__button">
              <Translate component="payment" keyword="back-home" />
            </LocalizedLink>
            <LocalizedLink to="/checkout" className="section__button">
              <Translate component="payment" keyword="back-checkout" />
            </LocalizedLink>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="payment-return">
      <div className="old-container">{content}</div>
    </div>
  );
};

export default observer(PaymentReturn);
