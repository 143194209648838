import React, { useState, useEffect } from 'react';
import Translate from '../Translate/Translate';
import classNames from 'classnames';

interface Props {
  prices: PriceItem[];
  priceLabel?: boolean;
  productNew?: boolean;
  discountPriceClassname?: string;
  defaultPriceClassName?: string;
  discountLabelClassname?: string;
}

type PriceItem = {
  type: PriceType;
  value: number;
};

export enum PriceType {
  Default = 'default',
  Sale = 'sale',
  Client = 'client',
  ClientExtra = 'client_extra',
  Campaign = 'campaign_sale',
}

const Price = ({
  prices,
  priceLabel,
  productNew,
  discountPriceClassname,
  defaultPriceClassName,
  discountLabelClassname,
}: Props) => {
  const [minPrice, setMinPrice] = useState<PriceItem | null>(null);
  const [maxPrice, setMaxPrice] = useState<PriceItem | null>(null);
  const [hasDiscount, setHasDiscount] = useState<boolean>(false);

  useEffect(() => {
    if (prices.length > 0) {
      const min = prices.reduce((min, price) =>
        price.value < min.value ? price : min,
      );
      const max = prices.reduce((max, price) =>
        price.value > max.value ? price : max,
      );

      setMinPrice(min);
      setMaxPrice(max);
      setHasDiscount(prices.length > 1);
    }
  }, [prices]);

  if (!minPrice || !maxPrice) {
    return null;
  }

  const labels = {
    sale: <Translate component="product" keyword="sale-win" />,
    client: <Translate component="product" keyword="sale-client-title" />,
    client_extra: <Translate component="product" keyword="sale-client-title" />,
    campaign_sale: <Translate component="product" keyword="sale-win" />,
    discount_code: (
      <Translate component="product" keyword="discount_code-title" />
    ),
  };

  const label = labels[minPrice.type];

  const result = (maxPrice.value - minPrice.value).toFixed(2);

  let priceContent = null;

  if (hasDiscount) {
    priceContent = (
      <span
        className={classNames('font-axi-bold', {
          'text-rgreen':
            minPrice.type === PriceType.Client ||
            minPrice.type === PriceType.ClientExtra,
          'text-black':
            minPrice.type === PriceType.Sale ||
            minPrice.type === PriceType.Campaign,
        })}
      >
        <span className={classNames(minPrice.type, discountPriceClassname)}>
          {minPrice.value.toFixed(2)} &euro;
        </span>
        <span
          className={classNames(
            'text-rgrey line-through font-normal',
            maxPrice.type,
            defaultPriceClassName,
          )}
        >
          {maxPrice.value.toFixed(2)} &euro;
        </span>
      </span>
    );
  } else {
    priceContent = (
      <span className={classNames('font-axi-bold text-base', maxPrice.type)}>
        {maxPrice.value.toFixed(2)} &euro;
      </span>
    );
  }

  return (
    <div className="price">
      <div className="price__container">
        {priceContent}
        {priceLabel ? (
          <div
            className={classNames(
              'items-center max-w-fit flex mt-1 px-3 py-1 rounded-full',
              {
                'bg-rgreen text-white':
                  minPrice.type === PriceType.Client ||
                  minPrice.type === PriceType.ClientExtra,
                'bg-ryellow text-black':
                  minPrice.type === PriceType.Sale ||
                  minPrice.type === PriceType.Campaign,
                '!bg-rgreen text-white': productNew,
                hidden: label == null,
              },
            )}
          >
            <p
              className={classNames(
                'font-axi-book',
                minPrice.type,
                discountLabelClassname,
              )}
            >
              {label}
              {(minPrice.type === PriceType.Sale ||
                minPrice.type === PriceType.Campaign) && (
                <span className="font-axi-bold ml-1">{`${result} €`}</span>
              )}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Price;
