import React, { useState, useEffect, useRef } from 'react';
import { useRefinementList } from 'react-instantsearch';
import Open from '../../images/chevron-down.svg';
import Checkmark from '../../images/checkmark-w.svg';
import CheckmarkDark from '../../images/checkmark-b.svg';
import { useOnClickOutside } from 'usehooks-ts';
import Translate from '../Translate/Translate';
import { translate } from '../../services/Translations';
import classNames from 'classnames';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  MinusIcon,
} from '@heroicons/react/20/solid';

interface AlgoliaFacetRefinementProps {
  facetName: string;
}

const AlgoliaFacetRefinement = ({ facetName }: AlgoliaFacetRefinementProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const {
    items,
    isShowingMore,
    canToggleShowMore,
    toggleShowMore,
    refine,
    searchForItems,
  } = useRefinementList({
    attribute: facetName,
    showMore: true,
    limit: 7,
    showMoreLimit: 450,
    sortBy: ['isRefined:desc', 'count'],
  });
  const [isActive, setIsActive] = useState(false);
  const filterMenuRef = useRef(null);
  const [translatedPlaceholder, setTranslatedPlaceholder] = useState('');

  useEffect(() => {
    searchForItems(searchTerm);
  }, [searchTerm]);

  const handleClickOutside = () => {
    setIsActive(false);
  };

  useOnClickOutside(filterMenuRef, handleClickOutside);

  const itemIsRefined = items.filter((item) => item.isRefined).length;

  useEffect(() => {
    const translatePlaceholder = async () => {
      const translated =
        facetName === 'size'
          ? await translate('facet-refinement', 'find-size')
          : facetName === 'shops'
            ? await translate('facet-refinement', 'find-shop')
            : facetName === 'brand'
              ? await translate('facet-refinement', 'find-brand')
              : facetName === 'color' &&
                (await translate('facet-refinement', 'find-color'));
      setTranslatedPlaceholder(translated);
    };

    translatePlaceholder();
  }, []);
  return (
    <div
      ref={filterMenuRef}
      className={classNames(
        'relative border-b lg:border-none lg:mr-3 cursor-pointer',
        {
          'pb-3 lg:pb-0': isActive,
        },
      )}
    >
      <div
        onClick={() => setIsActive(!isActive)}
        className={classNames(
          'flex py-2 justify-between border-rgrey-light cursor-pointer w-full lg:w-fit lg:border lg:rounded lg:px-3',
          {
            'border-black': itemIsRefined > 0,
          },
        )}
      >
        <p
          className={classNames(
            'h4 uppercase -mb-1 lg:normal-case lg:text-s lg:pr-1 lg:pt-0.5 lg:font-axi-book',
            {
              '!font-axi-bold': itemIsRefined > 0,
            },
          )}
        >
          {facetName === 'size' ? (
            <Translate component="facet-refinement" keyword="size" />
          ) : facetName === 'brand' ? (
            <Translate component="facet-refinement" keyword="brand" />
          ) : facetName === 'color' ? (
            <Translate component="facet-refinement" keyword="color" />
          ) : (
            facetName === 'shops' && (
              <Translate component="facet-refinement" keyword="shops" />
            )
          )}
        </p>
        <div className="flex">
          {itemIsRefined > 0 && (
            <div className="bg-black text-white px-2 flex items-center rounded label font-axi-bold mr-6 lg:mx-2">
              {itemIsRefined}
            </div>
          )}
          {isActive ? (
            <>
              <ChevronUpIcon className="hidden lg:block lg:w-5 lg:h-5" />
              <MinusIcon className="lg:hidden w-6 h-6" />
            </>
          ) : (
            <ChevronDownIcon className="lg:w-5 lg:h-5 w-6 h-6" />
          )}
        </div>
      </div>
      {isActive && (
        <div className="h-fit overflow-x-auto max-h-72 lg:mt-0.5 lg:pt-6 lg:px-4 lg:pb-7 lg:bg-white lg:border lg:border-rgrey-light lg:rounded lg:w-[335px] lg:max-h-96 lg:absolute lg:z-10">
          <div className="relative">
            <input
              className="rounded w-full pb-3 bg-rgrey-light border-none focus:outline-none focus:ring-0"
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              placeholder={translatedPlaceholder}
            />
            <MagnifyingGlassIcon className="w-5 h-5 absolute right-4 top-3 z-10" />
          </div>
          <div className="flex px-0.5 flex-wrap gap-3 justify-start mt-4">
            {items.map((item) => (
              <button
                onClick={() => refine(item.value)}
                className="relative"
                key={item.value}
              >
                {item.isRefined && facetName === 'color' && (
                  <img
                    className="px-2 absolute top-2.5 stroke-black"
                    src={
                      item.label === 'Valge'
                        ? CheckmarkDark
                        : item.label === 'Белый'
                          ? CheckmarkDark
                          : Checkmark
                    }
                  />
                )}
                {facetName === 'color' ? (
                  <div
                    className={classNames('w-8 h-8 rounded-full', {
                      'bg-black':
                        item.label === 'Must' || item.label === 'Черный',
                      'bg-white border':
                        item.label === 'Valge' || item.label === 'Белый',
                      'bg-blue-500':
                        item.label === 'Sinine' || item.label === 'Cиний',
                      'bg-gray-500':
                        item.label === 'Hall' || item.label === 'Серый',
                      'bg-pink-500':
                        item.label === 'Roosa' || item.label === 'Розовый',
                      'bg-[#7ABC12]':
                        item.label === 'Roheline' || item.label === 'Зеленый',
                      'bg-[#FF4B4B]':
                        item.label === 'Punane' || item.label === 'Красный',
                      'bg-[#d7c7a5]':
                        item.label === 'Beež' || item.label === 'Бежевый',
                      'bg-[#9747FF]':
                        item.label === 'Lilla' || item.label === 'Лиловый',
                      'bg-[#FF8B00]':
                        item.label === 'Oranž' || item.label === 'Оранжевый',
                      'bg-[#FFDF00]':
                        item.label === 'Kollane' || item.label === 'Желтый',
                      'bg-[#C6997B]':
                        item.label === 'Pruun' || item.label === 'Коричневый',
                    })}
                  ></div>
                ) : (
                  <div>
                    <div
                      className={classNames('border rounded px-4 py-2', {
                        'bg-black text-white border-black': item.isRefined,
                      })}
                    >
                      {item.label}
                    </div>
                  </div>
                )}
              </button>
            ))}

            {canToggleShowMore && (
              <button
                className="w-full pt-3 flex justify-center"
                onClick={() => toggleShowMore()}
              >
                <p className="paragraph font-axi-bold mr-4">
                  {isShowingMore ? (
                    <Translate
                      component="facet-refinement"
                      keyword="show-less"
                    />
                  ) : (
                    <Translate
                      component="facet-refinement"
                      keyword="show-more"
                    />
                  )}
                </p>
                <img
                  className={`w-6 h-6 ${
                    isShowingMore ? 'origin-center rotate-180' : ''
                  }`}
                  src={Open}
                />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AlgoliaFacetRefinement;
