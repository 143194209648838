import React from 'react';
import { inject } from 'mobx-react';
import Product from '../../models/Product';
import Price from '../Price/Price';
import DiscountPercentage from '../Price/DiscountPercentage';
import FavouriteIcon from '../Favourite/FavouriteIcon';
import LocalizedLink from '../LocalizedLink';
import AuthenticationStore from '../../stores/Authentication';
import { SendEventForHits } from 'instantsearch.js/es/lib/utils';
import ProductBlockImages from './ProductBlockImages';

interface Props {
  product: Product;
  authentication?: AuthenticationStore;
  slider?: boolean;
  isFocused?: boolean;
  sendEvent?: SendEventForHits;
}

@inject('authentication')
export default class ProductBlock extends React.Component<Props> {
  ref: HTMLElement;

  constructor(props) {
    super(props);

    this.ref = null;
  }

  componentDidMount() {
    this.scrollToThisProduct();
  }

  componentDidUpdate() {
    this.scrollToThisProduct();
  }

  scrollToThisProduct() {
    const { isFocused } = this.props;
    if (isFocused && this.ref) {
      // Timeout is required to work around render race conditions.
      // Probably can be resolved in some better way.
      setTimeout(() => {
        this.ref.scrollIntoView({
          block: 'center',
        });
      }, 100);
    }
  }

  public render() {
    const { product, sendEvent } = this.props;

    let urlQuery = ``;
    if (product?.data?.__queryID) {
      urlQuery = `?queryId=${product.data.__queryID}`;
    }

    return (
      <div
        className="mb-4"
        onClick={() => {
          if (sendEvent) {
            sendEvent('click', product.data, 'Product clicked');
          }
        }}
        ref={(ref) => {
          this.ref = ref;
        }}
      >
        <div className="relative">
          <LocalizedLink to={`/toode/${product.slug}${urlQuery}`}>
            <ProductBlockImages product={product} />
          </LocalizedLink>
          <FavouriteIcon
            productNew={product.isNew}
            productSlug={product.slug}
            componentClassname="absolute top-3 right-3 rounded-full bg-rgrey-middle"
          />
          <DiscountPercentage
            prices={product.prices(true)}
            productNew={product.isNew}
            componentClassname="absolute left-0 !text-xs"
            containerClassname="items-start pt-4 pl-3"
          />
        </div>
        <LocalizedLink to={`/toode/${product.slug}`}>
          <div className="pt-1">
            <span className="label text-s">{product.title}</span>
          </div>
          <div className="font-axi-book text-s pt-1">
            <span className="capitalize">{product.brandTitle}</span>{' '}
            {product.gridTitle}
          </div>
          <div className="font-axi-bold text-base pt-1">
            <Price
              prices={product.prices(true)}
              discountPriceClassname="text-black mr-3"
            />
          </div>
        </LocalizedLink>
      </div>
    );
  }
}
