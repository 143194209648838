import React from 'react';
import Translate from '../../../components/Translate/Translate';
import JobOfferBanner from './JobOfferBanner';
import JobOfferBoxes from './JobOfferBoxes';
import JobOfferImages from './JobOfferImages';
import JobOffersCarousel from './JobOffersCarousel';
import JobOffersSection from './JobOffersSection';
import JobOfferVideo from './JobOfferVideo';
import './styles/index.scss';

const JobOffersPage = () => {
  return (
    <div className="component-jobOffersPage">
      <JobOfferVideo />
      <JobOfferBoxes />
      <div className="component-jobOffersPage-title">
        <h2>
          <Translate component="jobOffers" keyword="title" />
        </h2>
        <p>
          <Translate component="jobOffers" keyword="sub-title" />
        </p>
      </div>
      <JobOfferImages />
      <JobOffersSection />
      <JobOfferBanner />
      <JobOffersCarousel />
    </div>
  );
};

export default JobOffersPage;
