import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import useStores from '../../hooks/useStores';
import Product from './Product';
import ErrorBanner from '../../components/404';
import { InstantSearch } from 'react-instantsearch';
import { searchClient } from '../../services/Algolia';
import config from '../../services/Config';

const PRODUCTS_VIEWED = gql`
  mutation algoliaProductsViewed($productIds: [String]!, $page: String) {
    algoliaProductsViewed(productIds: $productIds, page: $page)
  }
`;

const PRODUCT_ADDED_TO_CART = gql`
  mutation algoliaProductAddToCart($productIds: [String]!, $queryId: String) {
    algoliaProductAddToCart(productIds: $productIds, queryId: $queryId)
  }
`;

const ProductIndex = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { slug } = useParams<{ slug: string }>();
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { products } = useStores();
  const [productsViewed] = useMutation(PRODUCTS_VIEWED);
  const [productAddedToCart] = useMutation(PRODUCT_ADDED_TO_CART);

  const urlParams = new URLSearchParams(search);
  const queryId = urlParams.get('queryId');

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const product = await products.loadBySlug(slug);
        setCurrentProduct(product);
        productsViewed({
          variables: {
            productIds: [`p${product.id}`],
            page: 'PDP',
          },
        });
      } catch (error) {
        setIsLoading(false);

        // Failed to load product. Probably 404.
        throw error;
      }

      setIsLoading(false);
    };

    loadProduct();
  }, [slug]);

  if (isLoading) {
    return <div className="product loading" />;
  }

  if (!currentProduct) {
    return <ErrorBanner titleKeyword="product" />;
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={config.algoliaSearchIndex}
    >
      <Product
        slug={currentProduct.slug}
        navigate={navigate}
        product={currentProduct}
        productAddedToCart={(productId: number) => {
          productAddedToCart({
            variables: {
              productIds: [`p${productId}`],
              queryId: queryId,
            },
          });
        }}
      />
    </InstantSearch>
  );
};

export default ProductIndex;
