import React, { useEffect, useState } from 'react';
import Translate from '../Translate/Translate';
import classNames from 'classnames';

interface Props {
  prices: PriceItem[];
  productNew?: boolean;
  containerClassname?: string;
  componentClassname?: string;
}

type PriceItem = {
  type: PriceType;
  value: number;
};

enum PriceType {
  Default = 'default',
  Sale = 'sale',
  Client = 'client',
  ClientExtra = 'client_extra',
  Campaign = 'campaign_sale',
}

const DiscountPercentage = ({
  prices,
  productNew,
  containerClassname,
  componentClassname,
}: Props) => {
  const [minPrice, setMinPrice] = useState<PriceItem | null>(null);
  const [maxPrice, setMaxPrice] = useState<PriceItem | null>(null);
  const [hasDiscount, setHasDiscount] = useState<boolean>(false);

  useEffect(() => {
    if (prices.length > 0) {
      const min = prices.reduce((min, price) =>
        price.value < min.value ? price : min,
      );
      const max = prices.reduce((max, price) =>
        price.value > max.value ? price : max,
      );

      setMinPrice(min);
      setMaxPrice(max);
      setHasDiscount(prices.length > 1);
    }
  }, [prices]);

  let discountCaption = null;

  if (hasDiscount) {
    const discountPercentage = Math.round(
      ((maxPrice.value - minPrice.value) / maxPrice.value) * 100,
    );
    discountCaption = (
      <div
        className={`${classNames(
          'text-xs mr-1 font-axi-bold !py-1 text-center rounded-xl',
          {
            'bg-rgreen text-white w-12':
              minPrice.type === PriceType.Client ||
              minPrice.type === PriceType.ClientExtra,
            'bg-ryellow text-black w-12':
              minPrice.type === PriceType.Sale ||
              minPrice.type === PriceType.Campaign,
            '!bg-rgreen text-white w-20': productNew,
          },
          minPrice.type,
        )}`}
      >
        <span>
          {productNew ? (
            <span className="uppercase">
              <Translate component="new-notification" keyword="notification" />
              {` | -${discountPercentage}%`}
            </span>
          ) : (
            `-${discountPercentage}%`
          )}
        </span>
      </div>
    );
  }

  return (
    <div className={classNames('top-0', componentClassname)}>
      <div className={classNames('relative flex', containerClassname)}>
        {discountCaption}
      </div>
    </div>
  );
};

export default DiscountPercentage;
