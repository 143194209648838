import React from 'react';
import { inject } from 'mobx-react';

import Product from '../../models/Product';
import Price from '../Price/Price';
import DiscountPercentage from '../Price/DiscountPercentage';
import FavouriteIcon from '../Favourite/FavouriteIcon';
import Image from '../Image';
import LocalizedLink from '../LocalizedLink';
import { Sizes } from '../../constants/Image';

import './index.scss';
import AuthenticationStore from '../../stores/Authentication';

interface Props {
  product: Product;
  authentication?: AuthenticationStore;
  onProductClick?: () => void;
  slider?: boolean;
  isFocused?: boolean;
}

@inject('authentication')
export default class ProductBlock extends React.Component<Props> {
  ref: HTMLElement;

  constructor(props) {
    super(props);

    this.ref = null;
  }

  componentDidMount() {
    this.scrollToThisProduct();
  }

  componentDidUpdate() {
    this.scrollToThisProduct();
  }

  scrollToThisProduct() {
    const { isFocused } = this.props;
    if (isFocused && this.ref) {
      // Timeout is required to work around render race conditions.
      // Probably can be resolved in some better way.
      setTimeout(() => {
        this.ref.scrollIntoView({
          block: 'center',
        });
      }, 100);
    }
  }

  public render() {
    const { product, onProductClick } = this.props;

    return (
      <div
        className="component-product"
        onClick={onProductClick}
        ref={(ref) => {
          this.ref = ref;
        }}
      >
        <div className="component-product__image">
          <LocalizedLink to={`/toode/${product.slug}`}>
            <div className="wrapper">
              <Image
                className={'wrapper-img'}
                image={product.cover}
                defaultSize={Sizes.W540}
                sizes={[
                  '(max-width: 1024px) 50vw',
                  '(max-width: 1200px) 30vw',
                  '23vw',
                ]}
                allSizes={[Sizes.W300, Sizes.W540, Sizes.W800]}
                alt={`${product.brandTitle} ${product.title}`}
                width="540"
                height="540"
              />
            </div>
          </LocalizedLink>
          <FavouriteIcon
            productNew={product.isNew}
            productSlug={product.slug}
            componentClassname="absolute top-3 left-3 rounded-full bg-rgrey-middle"
          />
          <DiscountPercentage
            prices={product.prices(true)}
            productNew={product.isNew}
            componentClassname="absolute right-0"
            containerClassname="items-end pt-4 pr-2 sm:pr-3"
          />
        </div>
        <LocalizedLink to={`/toode/${product.slug}`}>
          <div className="component-product__info">
            <div className="component-product__info__left">
              <h5 className="component-product__info__left__category">
                {product.brandTitle} {product.gridTitle}
              </h5>
              <h5 className="component-product__info__left__title">
                {product.title}
              </h5>
            </div>
            <div className="component-product__info__right">
              <Price
                priceLabel={true}
                prices={product.prices(true)}
                productNew={product.isNew}
                discountPriceClassname="text-base mr-1"
                defaultPriceClassName="text-sm"
                discountLabelClassname="text-xs"
              />
            </div>
          </div>
        </LocalizedLink>
      </div>
    );
  }
}
