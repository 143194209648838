import React from 'react';
import { InstantSearch } from 'react-instantsearch';
import { searchClient, routing } from '../../services/Algolia';
import config from '../../services/Config';
import OrderedCategory from './OrderedCategory';

const Category = () => {
  return (
    <InstantSearch
      routing={routing}
      searchClient={searchClient}
      indexName={config.algoliaSearchIndex}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      <OrderedCategory />
    </InstantSearch>
  );
};

export default Category;
