import React from 'react';
import { createRoot } from 'react-dom/client';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import Rollbar from 'rollbar';
import { Provider as RollbarProvider } from '@rollbar/react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import TagManager from '@sooro-io/react-gtm-module';
import App from './App';
import Tv from './Tv';
import * as stores from './stores';
import * as Klaviyo from './services/Klaviyo';
import { isProd } from './services/Env';
import apolloClient from './services/Apollo';

import './styles/tailwind.scss';
import './styles/index.scss';

Klaviyo.init();

configure({ enforceActions: 'never' });

const rollbarConfig: Rollbar.Configuration = {
  enabled: isProd(),
  accessToken: '0eb7fe265c584c7eb0da82fc52543e31',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: 'production',
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: false,
      },
    },
  },
  hostSafeList: ['www.rademar.ee', 'rademar.ee'], // ignore thrid-party library errors
  checkIgnore: (_, args) => {
    // Ignore errors caused by network connection issues.
    if (
      args[0] === 'Network Error' ||
      `${args[0]}`.startsWith('Unreachable hosts')
    ) {
      return true;
    }

    return false;
  },
};
const tagManagerArgs = {
  gtmId: 'UA-61838717-1',
};

TagManager.initialize(tagManagerArgs);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <RollbarProvider config={rollbarConfig}>
    <ApolloProvider client={apolloClient}>
      <Provider {...stores}>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<App />} />
            <Route path="/et/*" element={<App locale="et" />} />
            <Route path="/en/*" element={<App locale="en" />} />
            <Route path="/ru/*" element={<App />} />
            <Route path="/lv/*" element={<App locale="lv" />} />
            <Route path="/tv/:shop/:screen" element={<Tv />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </RollbarProvider>,
);
