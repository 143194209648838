import React from 'react';
import HomeBlock from './HomeBlock';
import BlogBlock from './BlogBlock';
import BannersLayout from '../HomeBanners/BannersLayout';
export default class Home extends React.Component {
  public render() {
    return (
      <section className="home">
        <>
          <BannersLayout />
          <HomeBlock />
          <BlogBlock limit={2} />
        </>
      </section>
    );
  }
}
