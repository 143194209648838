import React from 'react';
import './styles/index.scss';
import Translate from '../Translate/Translate';
import LocalizedLink from '../LocalizedLink';
import { scrollToTop } from '../../services/Util';

const Social = () => {
  const scrollBackToTop = () => {
    scrollToTop();
  };

  return (
    <div className="component__social">
      <LocalizedLink onClick={scrollBackToTop} to="/blogi">
        <Translate component="footer" keyword="blogi-rademar" />
      </LocalizedLink>

      <a
        href="https://www.facebook.com/rademar.ee"
        target="_blank"
        rel="noreferrer"
      >
        Facebook
      </a>
      <a
        href="https://www.instagram.com/rademar.ee/"
        target="_blank"
        rel="noreferrer"
      >
        Instagram
      </a>
    </div>
  );
};

export default Social;
