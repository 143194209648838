import React, { useEffect, useState } from 'react';
import { Rings } from 'react-loader-spinner';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { QRCodeSVG } from 'qrcode.react';
import classNames from 'classnames';

const GET_PRODUCT = gql`
  query GetProduct($slug: String!) {
    product(slug: $slug) {
      id
      title
      sizesRange
      brand {
        id
        slug
      }
      prices {
        type
        value
      }
      media {
        urls {
          W1600
        }
      }
      categories {
        id
        longTitle
      }
    }
  }
`;

const GET_BRAND = gql`
  query GetBrand($slug: String!) {
    brandBySlug(slug: $slug) {
      id
      title
      logo
    }
  }
`;

const TvProduct = ({ slug }: { slug: string }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const { data } = useQuery(GET_PRODUCT, { variables: { slug } });
  const [getBrand, brandResponse] = useLazyQuery(GET_BRAND);
  const brandSlug = data?.product?.brand?.slug;

  useEffect(() => {
    if (brandSlug) {
      getBrand({ variables: { slug: brandSlug } });
    }
  }, [brandSlug]);

  useEffect(() => {
    if (slug) {
      setCurrentImage(0);
    }
  }, [slug]);

  useEffect(() => {
    const imageTimeout = setTimeout(() => {
      setCurrentImage(currentImage + 1);
    }, 7000);

    return () => {
      clearTimeout(imageTimeout);
    };
  }, [currentImage]);

  if (!data?.product) {
    return (
      <div className="grid h-screen place-items-center">
        <Rings
          height="100"
          width="100"
          color="#00A053"
          visible={true}
          ariaLabel="loading"
        />
      </div>
    );
  }

  const { product } = data;

  let imageUrl = '';
  // Use first image as cover
  if (product.media.length) {
    const currentImageIndex = currentImage % product.media.length;
    imageUrl = product.media[currentImageIndex].urls.W1600;
  }

  let categoryTitle = '';
  if (product.categories.length) {
    categoryTitle = product.categories[0].longTitle;
  }

  let minPriceType = 'client_extra';
  let minPrice = Infinity;
  let maxPrice = 0;
  product.prices.forEach(({ value, type }) => {
    if (value < minPrice) {
      minPriceType = type;
      minPrice = value;
    }
    if (value > maxPrice) {
      maxPrice = value;
    }
  });

  const discount = Math.round(100 - (minPrice * 100) / maxPrice);

  let brandLogo = '';
  if (brandResponse.data?.brandBySlug?.logo) {
    brandLogo = brandResponse.data.brandBySlug.logo;
  }

  return (
    <div className="flex w-full justify-center flex-col min-h-screen">
      <div className="flex-grow pt-16 px-16">
        <h1 className="font-axi-bold text-center text-8xl mb-6 leading-none">
          {product.title}
        </h1>
        <h2 className="font-axi-book text-rgrey text-center mb-12 text-5xl leading-none">
          {categoryTitle}
        </h2>
        <div className="mb-12 relative">
          <div
            className={classNames(
              'absolute top-14 px-9 py-7 text-6xl leading-none font-axi-bold rounded-r-lg',
              {
                'bg-rgreen text-white': minPriceType === 'client_extra',
                'bg-ryellow text-black': minPriceType === 'sale',
              },
            )}
          >
            -{discount}%
          </div>
          <img src={imageUrl} className="rounded-lg" />
        </div>
        <div className="flex justify-between items-stretch mb-14">
          <div className="flex flex-col px-6">
            <span
              className={classNames(
                'font-axi-bold text-[104px] leading-none mb-6',
                {
                  'text-rgreen': minPriceType === 'client_extra',
                  'text-ryellow': minPriceType === 'sale',
                },
              )}
            >
              {minPrice.toFixed(2)} &euro;
            </span>
            <span className="line-through font-axi-book text-[56px] leading-none">
              {maxPrice.toFixed(2)} &euro;
            </span>
          </div>
          {brandLogo ? (
            <img
              className="h-40 flex items-center px-6 w-80"
              src={`data:image/svg+xml;base64,${btoa(brandLogo)}`}
            />
          ) : null}
        </div>
        <div className="flex justify-between items-stretch px-6">
          <div className="w-1/2 flex flex-col">
            {product.sizesRange ? (
              <>
                <span className="text-4xl leading-10 font-axi-book text-gray-600 mb-7">
                  Suuruste vahemik
                </span>
                <span className="text-6xl leading-none font-axi-book">
                  {product.sizesRange}
                </span>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <footer className="border-t border-rgrey-light flex justify-between items-stretch">
        <div className="h-60 p-16 flex flex-row items-center">
          <QRCodeSVG
            value={`${window.location.origin}/toode/${slug}`}
            size={112}
          />
          <span className="font-axi-bold text-5xl ml-10">Ava e-poes</span>
        </div>
        <div className="flex p-16 items-center">
          <span className="font-axi-bold text-5xl">rademar.ee</span>
        </div>
      </footer>
    </div>
  );
};

export default TvProduct;
