import React from 'react';
import Slider from 'react-slick';
import { useRelatedProducts } from 'react-instantsearch';
import Translate from '../Translate/Translate';
import Product from '../../models/Product';
import ProductBlock from '../ProductBlock';
import Left from '../SlickArrow/Left';
import Right from '../SlickArrow/Right';

const Related2 = ({ productId }: { productId: string }) => {
  const { items } = useRelatedProducts({
    objectIDs: [`p${productId}`],
  });

  if (!items?.length) {
    return null;
  }

  const products = items.map((productData) => new Product(productData));
  const productBlocks = products.map((product) => (
    <ProductBlock key={product.slug} product={product} slider={true} />
  ));

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    // dotsClass: 'awesome-dots',
    customPaging: () => {
      return <span />;
    },
    prevArrow: <Left />,
    nextArrow: <Right />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="recommendations-block">
      <h2>
        <Translate component="product" keyword="recommended" />
      </h2>
      <div className="products-slick">
        <Slider {...settings}>{productBlocks}</Slider>
      </div>
    </div>
  );
};

export default Related2;
