import React, { useState } from 'react';
import SubmenuHoverable from './SubmenuHoverable';
import LocalizedLink from '../LocalizedLink';

type Props = {
  title: string;
  id: string;
  slug: string;
  subCategories: {
    title: string;
    id: string;
    slug: string;
  }[];
};

const MainMenuItemHoverable = ({ title, subCategories, slug }: Props) => {
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);

  return (
    <div
      className="p-3 mt-1"
      onMouseEnter={() => {
        setIsSubmenuVisible(true);
      }}
      onMouseLeave={() => {
        setIsSubmenuVisible(false);
      }}
    >
      <LocalizedLink
        onClick={() => {
          setIsSubmenuVisible(false);
        }}
        to={`/tooted/${slug}`}
        className="pb-1 border-b-2 border-transparent hover:border-rgreen"
        reloadDocument
      >
        <span className="uppercase relative font-axi-bold">{title}</span>
      </LocalizedLink>

      {isSubmenuVisible && subCategories.length ? (
        <div className="absolute top-12 bg-white opacity-[.98] z-50 p-7 flex overflow-y-scroll mx-auto my-0 border-b-white border-solid inset-x-0 max-h-auto">
          {subCategories.map((subCategory) => (
            <SubmenuHoverable
              key={subCategory.id}
              parentId={subCategory.id}
              title={subCategory.title}
              subCategorySlug={subCategory.slug}
              slug={slug}
              onClick={() => {
                setIsSubmenuVisible(false);
              }}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default MainMenuItemHoverable;
