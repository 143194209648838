import React, { useState } from 'react';
import Product from '../../models/Product';
import Image from '../Image';
import { Sizes } from '../../constants/Image';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
interface Props {
  product: Product;
}
const ProductBlockImages = ({ product }: Props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const nrOfImages = product.imageUrls.urls.W300.length;
  const handlePrevClck = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + nrOfImages) % nrOfImages,
    );
  };
  const handleNextClck = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % nrOfImages);
  };

  return (
    <>
      <div className="hidden md:block relative h-0 pt-[calc(360/360*100%)] rounded pb-1 group">
        <button
          className="absolute top-1/2 left-0 z-10 pr-2 opacity-0 group-hover:opacity-100 transition-opacity transform -translate-y-1/2"
          onClick={(e) => {
            e.preventDefault();
            handlePrevClck();
          }}
        >
          <ChevronLeftIcon className=" w-8 h-8 self-center opacity-50" />
        </button>
        <button
          className="absolute top-1/2 right-0 z-10 pl-2 opacity-0 group-hover:opacity-100 transition-opacity transform -translate-y-1/2"
          onClick={(e) => {
            e.preventDefault();
            handleNextClck();
          }}
        >
          <ChevronRightIcon className=" w-8 h-8 opacity-50 self-center" />
        </button>
        <Image
          className={
            'absolute top-0 left-0 rounded h-auto max-w-full bg-rgrey-lightlight object-cover'
          }
          image={{
            urls: {
              W300: product.imageUrls.urls.W300[currentImageIndex],
              W540: product.imageUrls.urls.W540[currentImageIndex],
              W800: product.imageUrls.urls.W800[currentImageIndex],
            },
          }}
          defaultSize={Sizes.W540}
          allSizes={[Sizes.W300, Sizes.W540, Sizes.W800]}
          alt={`${product.brandTitle} ${product.title}`}
          width="540"
          height="540"
        />
      </div>
      <div className="md:hidden relative h-0 pt-[calc(360/360*100%)] rounded pb-1">
        <Image
          className={
            'absolute top-0 left-0 rounded h-auto max-w-full bg-rgrey-lightlight object-cover '
          }
          image={product.cover}
          defaultSize={Sizes.W540}
          allSizes={[Sizes.W300, Sizes.W540, Sizes.W800]}
          alt={`${product.brandTitle} ${product.title}`}
          width="540"
          height="540"
        />
      </div>
    </>
  );
};
export default ProductBlockImages;
