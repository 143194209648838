import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import LocalizedLink from '../../LocalizedLink';
import Loader from '../../Loader/Loader';

const GET_BRANDS = gql`
  query VisibleBrands {
    visibleBrands {
      id
      title
      slug
      logoUrl
    }
  }
`;

const BrandsPage = () => {
  const { loading, error, data } = useQuery(GET_BRANDS);
  const [brandLogos, setBrandLogos] = useState([]);

  useEffect(() => {
    if (!loading && !error) {
      setBrandLogos(data.visibleBrands);
    }
  }, [loading, error, data]);

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error) {
    return null;
  }

  return (
    <div className="flex-nowrap">
      <div className="mt-12 mb-10">
        <div className="my-0 -mx-3 sm:flex sm:flex-wrap">
          {brandLogos.map((brand, id) => {
            const urlParams = new URLSearchParams();
            urlParams.set('brand', brand.title);
            const slug = `/tooted?${urlParams.toString()}`;
            return (
              <div
                className="flex flex-initial box-border py-3 px-3 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 3xl:w-1/6"
                key={id}
              >
                <LocalizedLink
                  className="w-full relative"
                  to={slug}
                  reloadDocument
                >
                  <div className="flex relative justify-center items-center h-32 border border-rgrey-light rounded-none">
                    <img
                      className="w-full h-auto max-w-[140px] max-h-[50px]"
                      src={brand.logoUrl}
                      alt={brand.title}
                    />
                  </div>
                </LocalizedLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BrandsPage;
