import React from 'react';
import SorterOptions from './SorterOptions';

const Sorter = () => {
  // TODO: Get labels from API. But avoid re-render because Algolia seems to not like it.
  const options = [
    { value: 'RELEVANCE', label: 'Populaarsed' },
    { value: 'NEW_ARRIVAL_DESC', label: 'Uuemad enne' },
    { value: 'PRICE_DESC', label: 'Kallimad enne' },
    { value: 'PRICE_ASC', label: 'Odavamad enne' },
  ];

  return <SorterOptions options={options} />;
};

export default Sorter;
