import React from 'react';
import { InstantSearch } from 'react-instantsearch';
import { searchClient } from '../../services/Algolia';
import config from '../../services/Config';
import SearchInput from './SearchInput';

const Search2 = () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={config.algoliaSearchIndex}
    >
      <SearchInput />
    </InstantSearch>
  );
};

export default Search2;
