import TagManager from '@sooro-io/react-gtm-module';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        hitType: 'pageview',
        page: location.pathname + location.search,
      },
    });
  }, [location]);
};

export default usePageTracking;
