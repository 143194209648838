import React, { useEffect, useState, Suspense, lazy } from 'react';

import { observer } from 'mobx-react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import aa from 'search-insights';
import { LiveChatWidget } from '@livechat/widget-react';
import useStores from './hooks/useStores';
import { Language } from './stores/Translations';
import { isLvDomain } from './services/Env';
import Header from './components/Header';
import Footer from './components/Footer';
import Social from './components/Social';
import Home from './components/Home';
import usePageTracking from './hooks/usePageTracking';
import {
  getPersonalisationKey,
  setPersonalisationKey,
} from './services/Personalisation';

import { translate } from './services/Translations';
import Shops from './components/Content/Shops/Shops';
import ClickAndTry from './components/Content/ClickAndTry/ClickAndTry';

const Category = lazy(() => import('./components/Category'));
const Brand = lazy(() => import('./components/Brand'));
const Checkout = lazy(() => import('./components/Checkout'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Content = lazy(() => import('./components/Content'));
const Favourite = lazy(() => import('./components/Favourite'));
const Product = lazy(() => import('./components/Product'));
const PaymentReturn = lazy(() => import('./components/Content/PaymentReturn'));
const JobOffers = lazy(() => import('./components/Content/JobOffers/index'));
const ReturnPage = lazy(() => import('./components/Content/ReturnPage'));
const BrandsPage = lazy(() => import('./components/Content/Brands/BrandsPage'));
const AboutUs = lazy(() => import('./components/Content/AboutUs'));
const Reset = lazy(() => import('./components/Profile/Reset'));
const NewsPage = lazy(() => import('./components/Content/News/News'));
const NewsSingle = lazy(
  () => import('./components/Content/News/NewsSingleIndex'),
);
const SetPassword = lazy(() => import('./components/SetPassword'));
interface Props {
  locale?: string;
}

const App = ({ locale }: Props) => {
  usePageTracking();
  const navigate = useNavigate();
  const { translations, authentication, user, cart } = useStores();
  const storedLang = translations.getStoredLanguage();
  const { loggedIn } = authentication;
  const [siteTitle, setSiteTitle] = useState('Rademar');
  const [siteDescription, setSiteDescription] = useState('Rademar');

  let appLocale = Language.ET;
  if (isLvDomain()) {
    appLocale = Language.LV;
  }
  if (locale && translations.isValidLanguage(locale)) {
    appLocale = locale as Language;
  }

  let redirectToStoredLocale = false;
  if (
    !translations.isDefaultLanguage(storedLang) &&
    translations.isValidLanguage(storedLang) &&
    storedLang !== appLocale
  ) {
    redirectToStoredLocale = true;
    appLocale = storedLang;
  }

  translations.setLanguage(appLocale);

  useEffect(() => {
    if (redirectToStoredLocale) {
      navigate(`/${appLocale}`, { replace: true });
    }
  }, [redirectToStoredLocale, appLocale]);

  useEffect(() => {
    const initCart = async () => {
      await cart.create();

      // Cart token is used for analytics and personalisation
      if (cart.token) {
        if (
          !getPersonalisationKey() ||
          !getPersonalisationKey().startsWith(cart.token)
        ) {
          setPersonalisationKey(cart.token);
          aa('setUserToken', getPersonalisationKey());
        }
      }
    };

    const initTranslations = async () => {
      const title = await translate('app', 'title');
      const description = await translate('app', 'description');
      setSiteTitle(title);
      setSiteDescription(description);
    };

    initTranslations();
    initCart();
    authentication.check();
  }, []);

  useEffect(() => {
    if (loggedIn && !user.loaded) {
      user.loadInfo();
    }
  }, [loggedIn]);

  const className = translations.isDefaultLanguage(appLocale)
    ? 'app-container'
    : `app-container lang-${appLocale}`;

  return (
    <>
      <div className={className}>
        <Helmet
          bodyAttributes={{
            class: className,
          }}
        >
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>
        <Header />
        <main className="content">
          <Suspense fallback={<div />}>
            <div className="old-container">
              <Routes>
                <Route index element={<Home />} />
                <Route path="toolaud" element={<Dashboard />} />
                <Route path="brand/*" element={<Brand />} />
                <Route path="checkout" element={<Checkout />} />
                <Route path="kauplused" element={<Shops />} />
                <Route element={<JobOffers />} path="tule-toole" />
                <Route path="tagastamine" element={<ReturnPage />} />
                <Route path="reset-password" element={<Reset />} />
                <Route path="brandid" element={<BrandsPage />} />
                <Route path="ettevottest" element={<AboutUs />} />
                <Route path="lemmikud" element={<Favourite />} />
                <Route path="payment/:status" element={<PaymentReturn />} />
                <Route path="toode/:slug" element={<Product />} />
                <Route path="tooted/*" element={<Category />} />
                <Route path="set-password" element={<SetPassword />} />
                <Route path="blogi" element={<NewsPage />} />
                <Route path="blogi/:slug" element={<NewsSingle />} />
                <Route path=":pageSlug" element={<Content />} />
                <Route path="telli-ja-proovi" element={<ClickAndTry />} />
              </Routes>
              <Social />
            </div>
          </Suspense>
        </main>
        <Footer />
      </div>
      <LiveChatWidget license="10492207" />
    </>
  );
};

export default observer(App);
